// src/App.tsx

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UsersPage = lazy(() => import('./pages/UsersPage'));
const UserEditPage = lazy(() => import('./pages/UserEditPage'));
const CreateUserPage = lazy(() => import('./pages/CreateUserPage'));
const CallHandlerPage = lazy(() => import('./pages/CallHandlerPage'));
// const LoginPage = lazy(() => import('./pages/LoginPage')); // If implemented

const App: React.FC = () => {
    return (
        <Router>
            <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/users" replace />} />
                        <Route
                            path="/users"
                            element={
                                <ProtectedRoute>
                                    <UsersPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users/:id"
                            element={
                                <ProtectedRoute>
                                    <UserEditPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/create-user"
                            element={
                                <ProtectedRoute>
                                    <CreateUserPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/call"
                            element={
                                <ProtectedRoute>
                                    <CallHandlerPage />
                                </ProtectedRoute>
                            }
                        />
                        {/* <Route path="/login" element={<LoginPage />} /> */}
                        {/* Add other routes here */}
                    </Routes>
                </Suspense>
            </Layout>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        </Router>
    );
};

export default App;
