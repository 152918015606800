// src/components/Shared/Header.tsx

import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';

const Header: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    CallPad Demo (Admin)
                </Typography>
                <Button
                    color="inherit"
                    component={RouterLink}
                    to="/users" // Refers to Businesses list
                >
                    Businesses
                </Button>
                <Button
                    color="inherit"
                    component={RouterLink}
                    to="/create-user" // Refers to Create Business
                >
                    Create Business
                </Button>
                {/* Add Login/Logout buttons based on auth status */}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
