// src/components/Shared/Footer.tsx

import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#f5f5f5' }}>
            <Typography variant="body1" align="center">
                © {new Date().getFullYear()} CallPad. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
