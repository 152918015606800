// src/components/Layout/Layout.tsx

import React from 'react';
import { Container } from '@mui/material';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Container component="main" sx={{ mt: 4, mb: 2, flexGrow: 1 }}>
                {children}
            </Container>
            <Footer />
        </div>
    );
};

export default Layout;
